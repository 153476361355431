/**
 * @generated SignedSource<<c5043cee9f760bc07ca3b1e0bed8e6c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksTopicWidgetQuery$data = {
  readonly appConfig: {
    readonly json: unknown | null | undefined;
  } | null | undefined;
  readonly topic: {
    readonly entityId: string;
    readonly " $fragmentSpreads": FragmentRefs<"hooksTopic" | "topicAdsTopic" | "topicDescriptionTopic" | "topicMetadataDisplayTopic">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"chinaScienceDiscoveryModuleQueueQuery" | "discoveryWidgetPostMagazineQueueQuery">;
  readonly " $fragmentType": "hooksTopicWidgetQuery";
};
export type hooksTopicWidgetQuery$key = {
  readonly " $data"?: hooksTopicWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksTopicWidgetQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksTopicWidgetQuery"
};

(node as any).hash = "1ec1a3159f9b49db5cdcabe4e3429e62";

export default node;
